import React, { useMemo, useState } from "react";
import useFetch from "./custom/useFetch";
import useFetchv2 from "./custom/useFetchv2";

const Employee = () => {
  const { data: emp, isLoading, error } = useFetchv2("empdata.php");
  const { data: empDept } = useFetchv2("empdept.php");
  let user = sessionStorage.getItem("data");
  user = JSON.parse(user);
  let userDept =
    empDept &&
    empDept.filter((f) => f.user_id === user.ID).map((d) => d.dept_id);
  const [search, setsearch] = useState("");
  let empD =
    emp && userDept && emp.filter((d) => userDept.indexOf(d.dept_id) !== -1);
  const dataEmp = useMemo(() => {
    const searchRegex = search.src && new RegExp(`${search.src}`, "gi");
    return (
      empD &&
      empD.filter(
        (d) => !searchRegex || searchRegex.test(d.name)
        //   && userDept.indexOf(d.dept_id !== -1)
        //   (search.att_check ||
        //     d.attendance_status === null ||
        //     d.attendance_status === "Not Filled")
      )
    );
  }, [empD, search.src]);
  return (
    <>
      <div className="w-100">
        {console.log(empD)}
        <div>Employee </div>
        {isLoading ? (
          "Please wait..."
        ) : (
          <div className="w-100">
            {dataEmp &&
              dataEmp.map((d) => (
                <div key={d.id}>
                  <p>
                    | {d.kode} | {d.name} | {d.dept}
                  </p>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Employee;
