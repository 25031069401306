import React from "react";
import AbsenDetail from "../absendetail";
import Employee from "../employee";

const rute = [
  {
    path: "/",
    element: <AbsenDetail />,
  },
  {
    path: "/emp",
    element: <Employee />,
  },
];

export default rute;
