import React from "react";
const Page404 = () => {
  return (
    <>
      <div>page 404</div>
    </>
  );
};

export default Page404;
