import { useState, useEffect } from "react";
import API from "../config/api";

const useFetchv2 = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(async () => {
    const abortCtr = new AbortController();
    try {
      let res = await API.get(`${url}`, {
        signal: abortCtr.signal,
      });
      // console.log(res);
      // res = res.json();
      setData(res.data);
      setIsLoading(false);
      setError(null);
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("fetch data aborted");
      } else {
        setIsLoading(false);
        setError(err.message);
      }
      console.log(err);
    }
    return () => abortCtr.abort();
  }, [url]);

  return { data, isLoading, error };
};

export default useFetchv2;
