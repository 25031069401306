import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./assets/css/navbar.css";

const Navbar = () => {
  const [logout, setLogout] = useState(false);
  return (
    <>
      <nav className="w-100">
        <div
          className="row col-md-12"
          style={{
            margin: "0px",
            padding: "0px",
            background: "#0d1a29",
            textAlign: "left",
            height: "50px",
            color: "white",
          }}
        >
          <div
            className="col-md-6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h4>ABSENSI KARYAWAN</h4>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              fontSize: "14px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
              <NavLink className="nav-link" to="/emp">
                Employee
              </NavLink>
            </div>
            <div
              className="nav-link"
              style={{ display: "flex", alignItems: "center" }}
              onMouseEnter={(e) => setLogout(true)}
              onMouseLeave={(e) => setLogout(false)}
            >
              {logout ? (
                <div
                  onClick={() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload(true);
                  }}
                >
                  <i className="bi bi-door-open"></i> Logout
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      width: "27px",
                      height: "27px",
                      background: "royalblue",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "600",
                      // marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {
                        JSON.parse(sessionStorage.getItem("data"))
                          .Op_Name.toUpperCase()
                          .split("")[0]
                      }
                    </div>
                  </div>

                  <div>
                    Hi,{" "}
                    {JSON.parse(
                      sessionStorage.getItem("data")
                    ).Op_Name.toUpperCase()}
                    !
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
