import React, { Component } from "react";
import "./assets/css/login.css";
import API from "./config/api";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: "",
      isRemember: false,
      message: "",
      error: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);

    const headers = {
      "Access-Control-Allow-Methods": "GET",
      "Content-Type": "application/json; charset=UTF-8",
    };

    API.get(`login.php?json=${JSON.stringify(this.state)}`)
      .then((res) => {
        this.setState({ message: res.data.message }, () =>
          console.log(res.data.message)
        );
        res.data.token
          ? this.setState({ token: res.data.token }, () => {
              sessionStorage.clear();
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("data", JSON.stringify(res.data.data));
              window.location.reload(true);
            })
          : console.log("res", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  render() {
    return (
      <div className="form-center text-center">
        <form className="form-signin" method="get" onSubmit={this.handleSubmit}>
          <img
            className="mb-4"
            src="./logo512.png"
            alt=""
            width="72"
            height="72"
          />
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <small style={{ color: "red" }}>{this.state.message}</small>
          <label className="sr-only" htmlFor="username">
            Email address
          </label>
          <input
            className="form-control"
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            required
            autoFocus
            onChange={this.handleChange}
          />
          <label className="sr-only" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            name="password"
            id="inputPassword"
            placeholder="Password"
            required
            onChange={this.handleChange}
          />
          <div className="form-check checkbox mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              value="remember-me"
              onChange={() => {
                this.setState(
                  {
                    isRemember: !this.state.isRemember,
                  },
                  () => console.log(this.state)
                );
              }}
            />
            <label className="form-check-label"> Remember me</label>
          </div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Sign in
          </button>
          <p className="mt-5 mb-3 text-muted">&copy; 2021-2022</p>
        </form>
      </div>
    );
  }
}

export default Login;
