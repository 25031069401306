import "./App.css";
import Login from "./components/login";
import Navbar from "./components/navbar";
import { Route, Routes } from "react-router-dom";
import rute from "./components/config/routes";
import Page404 from "./components/page404";

function App() {
  if (!sessionStorage.getItem("token")) {
    return <Login />;
  }

  return (
    <div className="App">
      <div className="w-100">
        <Navbar />
      </div>
      <div className="w-100">
        <Routes>
          {rute.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={route.element}
            />
          ))}
          <Route path={"/*"} element={<Page404 />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
