import React, { Component, useMemo } from "react";
import AbsenDetailTabel from "./absendetailtabel";
import API from "./config/api";
import { ShortDate } from "./custom/dateFormatter";

class AbsenDetail extends Component {
  constructor(props) {
    let today = new Date(),
      nowdate = new Date(today.getTime()),
      lastmonth = new Date(today.setMonth(today.getMonth() - 1)),
      dateStart =
        lastmonth.getFullYear() +
        "-" +
        ("0" + (lastmonth.getMonth() + 1)).slice(-2) +
        "-" +
        26,
      dateEnd =
        nowdate.getFullYear() +
        "-" +
        ("0" + (nowdate.getMonth() + 1)).slice(-2) +
        "-" +
        25;
    console.log(dateStart, dateEnd);

    super(props);
    this.state = {
      filter: "",
      dataJml: [],
      dataEmp: [],
      kirim: {
        start: dateStart,
        end: dateEnd,
      },
    };
  }

  componentDidMount = () => {
    this.getDataJml();
  };

  getDataJml = async () => {
    console.log(this.state);
    let user = sessionStorage.getItem("data");
    user = JSON.parse(user);
    let userDept = [];
    // console.log(user);
    try {
      let res = await API.get(`empdept.php`);
      res = await res.data;
      res = await res
        .filter((f) => f.user_id === user.ID)
        .map((d) => d.dept_id);
      console.log(res);
      let res1 = await API.post(
        `absenjml.php`,
        JSON.stringify(this.state.kirim)
      );
      res1 = await res1.data;
      res1 = await res1.filter((d) => res.indexOf(d.dept_id) !== -1);
      console.log(res1);
      this.setState({ dataEmp: res, dataJml: res1 }, () =>
        console.log("data Emp", this.state)
      );
    } catch (error) {
      console.log(error);
      this.setState({ errorMsg: "Error retrieving data." });
    }
  };

  handleJml = async (e) => {
    e.preventDefault();
    await API.post(`absenjml.php`, JSON.stringify(this.state.kirim))
      // API.get(`absendet.php?json=${JSON.stringify(this.state.kirim)}`)
      .then((res) => {
        this.setState({ dataJml: res.data }, () => {
          console.log("data jumlah", res.data);
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMsg: "Error retrieving data." });
      });
  };

  handleFetch = async (e, i) => {
    e.preventDefault();
    let x = { ...this.state.kirim, empid: i };
    console.log(x);
    await API.post(`absendet.php`, JSON.stringify(x))
      // API.get(`absendet.php?json=${JSON.stringify(this.state.kirim)}`)
      .then((res) => {
        this.setState({ dataDet: res.data }, () => {
          console.log("data detail", res.data);
        });
        this.setState(
          {
            total:
              Math.round(
                res.data.reduce((a, b) => a + parseFloat(b.jam), 0) * 100
              ) / 100,
          },
          () => {
            console.log("total");
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMsg: "Error retrieving data." });
      });
  };
  handleRaw = async (e, i) => {
    e.preventDefault();
    let x = { ...this.state.kirim, empid: i };
    console.log(x);
    await API.post(`absenraw.php`, JSON.stringify(x))
      // API.get(`absendet.php?json=${JSON.stringify(this.state.kirim)}`)
      .then((res) => {
        this.setState({ dataDet: res.data }, () => {
          console.log("data detail", res.data);
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMsg: "Error retrieving data." });
      });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => console.log(`${name}`, value));
  };

  handleDate = (e) => {
    const { name, value } = e.target;
    this.setState({ kirim: { ...this.state.kirim, [name]: value } }, () => {
      console.log(`${name}`, value);
      console.log(this.state);
    });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    //return isValid;
    this.setState({ search: value }, () => console.log(`${name}`, value));
    !value
      ? sessionStorage.removeItem("cari")
      : sessionStorage.setItem("cari", value);
  };

  render() {
    const { filter, dataJml } = this.state;
    const lowercasedFilter = filter.toString().toLowerCase();
    const filteredDataJml = dataJml.filter((item) => {
      return Object.keys(item).some(
        (key) => item[key].toLowerCase().includes(lowercasedFilter)
        // item[key].toLowerCase().includes(filter.toLowerCase()) || !filter
      );
    });
    return (
      <>
        <div
          className="row col-12 col-md-12"
          style={{ margin: "0", padding: "0" }}
        >
          <div className="w-100" style={{ height: "25px" }}></div>
          <div className="w-100">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <p style={{ textAlign: "left", lineHeight: "0" }}>
                    <h4 className="title ">DETAIL PRESENSI KARYAWAN</h4>
                    <small>
                      {`${ShortDate(this.state.kirim.start)} - ${ShortDate(
                        this.state.kirim.end
                      )}`}
                    </small>
                  </p>
                </div>
                <div className="row col-md-8">
                  <div className="form-group m-2" style={{ textAlign: "left" }}>
                    <label htmlFor="search" style={{ marginBottom: "0px" }}>
                      search
                    </label>
                    <br />
                    <input
                      type="text"
                      name="filter"
                      id="filter"
                      placeholder="employee name"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div
                    className="form-group m-2 "
                    style={{ textAlign: "left" }}
                  >
                    <label htmlFor="startdate" style={{ marginBottom: "0px" }}>
                      mulai
                    </label>
                    <br />
                    <input
                      type="date"
                      name="start"
                      id="start"
                      onChange={this.handleDate}
                    />
                  </div>
                  <div
                    className="form-group m-2 "
                    style={{ textAlign: "left" }}
                  >
                    <label htmlFor="enddate" style={{ marginBottom: "0px" }}>
                      akhir
                    </label>
                    <br />
                    <input
                      type="date"
                      name="end"
                      id="end"
                      onChange={this.handleDate}
                    />
                  </div>

                  <div className="form-group m-2" style={{ textAlign: "left" }}>
                    <label htmlFor="button" style={{ marginBottom: "0px" }}>
                      .
                    </label>
                    <br />
                    <button
                      className="btn btn-primary btn-sm align-self-end"
                      onClick={this.handleJml}
                    >
                      Fetch Data
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ marginTop: "10px" }}>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>no</th>
                        <th>nama</th>
                        {/* <th>bulan</th> */}
                        <th>absen</th>
                        <th>lupa</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "12px", lineHeight: "1" }}>
                      {filteredDataJml
                        // .filter((dataJml) => dataJml.kode === this.state.emps)
                        .map((d, i) => (
                          <>
                            <tr
                              key={i}
                              name={d.kode}
                              value={d.kode}
                              // onMouseOver={() => {
                              //   this.setState(
                              //     {
                              //       kirim: {
                              //         ...this.state.kirim,
                              //         empid: d.kode,
                              //       },
                              //     },
                              //     () => console.log("empid", this.state.kirim)
                              //   );
                              // }}
                            >
                              <td>{d.kode}</td>
                              <td style={{ textAlign: "left" }}>{d.name}</td>
                              <td>{d.absen}</td>
                              <td>{d.lupa}</td>
                              <td>
                                <i
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => this.handleFetch(e, d.kode)}
                                >
                                  details
                                </i>
                                <i
                                  className="btn btn-info btn-sm"
                                  onClick={(e) => this.handleRaw(e, d.kode)}
                                >
                                  raw data
                                </i>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {this.state.dataDet && (
                <div
                  className="fixed-bottom bg-light"
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>nomor</th>
                        <th>lokasi</th>
                        <th>nama karyawan</th>
                        <th>tanggal</th>
                        <th>masuk</th>
                        <th>pulang</th>
                        <th>{this.state.total} jam</th>
                        <th>
                          <i
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                              this.setState({ dataDet: "" });
                            }}
                          >
                            clear data
                          </i>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "12px", lineHeight: "1" }}>
                      {this.state.dataDet &&
                        this.state.dataDet.map((d) => (
                          <>
                            <tr key={d.id}>
                              <td>{d.kode}</td>
                              <td>{d.lokasi}</td>
                              <td>{d.name}</td>
                              <td>{d.date}</td>
                              <td>{d.masuk === d.pulang ? d.min : d.masuk}</td>
                              <td>{d.masuk === d.pulang ? d.max : d.pulang}</td>
                              <td>{d.jam}</td>
                              <td></td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AbsenDetail;
